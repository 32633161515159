import React, { Fragment } from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import { GatsbyImage } from 'gatsby-plugin-image'

// Needs to be class component in order to pass variables to GraphQL query
// Static queries, do not accept variables
// https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/#known-limitations
interface StateProps {
    pageContext: PageContext
}

interface PageContext {
    authorPages: number
    author: string
}

class BlogAuthorListTemplate extends React.Component<StateProps> {
    componentDidMount() {
        setTimeout(function () {
            document.querySelector<HTMLInputElement>('.searchboxinput').focus()
            document.querySelector<HTMLInputElement>('.searchboxinput').select()
        }, 100)
    }
    render() {
        const pageSEO = get(this, 'props.data.allContentfulPages.edges[0].node')
        // PAGINATION
        const { authorPages, author } = this.props.pageContext
        // Get current page from window
        let pageNumber
        if (typeof window !== 'undefined') {
            pageNumber = window.location.href.substring(window.location.href.lastIndexOf('/') - 1)
            pageNumber = parseInt(pageNumber[0])
        }
        let pagination

        let pageTitle
        let paginationLink
        // Set Page Title
        // Need lower cases for urls
        pageTitle = author.toLowerCase()
        paginationLink = pageTitle.replace(/\s+/g, '-')

        // Parse it
        if (Number.isNaN(pageNumber) || !pageNumber) {
            // Case for page 1
            // For one page return current
            if (authorPages === 1) {
                pagination = <Fragment></Fragment>
                // For 2 pages  return  current +1
            } else if (authorPages === 2) {
                pagination = (
                    <Fragment>
                        <ul className='pagination'>
                            <Link to={`/author/${paginationLink}/`}>
                                <li className='pagination__item pagination__item--current '>1</li>
                            </Link>
                            <Link to={`/author/${paginationLink}/2/`} rel='next'>
                                <li className='pagination__item pagination__item--next'>2</li>
                            </Link>
                            <Link to={`/author/${paginationLink}/2/`} rel='next'>
                                <li className='pagination__item pagination__item--next'>»</li>
                            </Link>
                            <Link to={`/author/${paginationLink}/${authorPages}/`} rel='next'>
                                <li className='pagination__item pagination__item--next'>»»</li>
                            </Link>
                        </ul>
                    </Fragment>
                )
                // Return current +2
            } else {
                pagination = (
                    <Fragment>
                        <ul className='pagination'>
                            <Link to={`/author/${paginationLink}/`}>
                                <li className='pagination__item pagination__item--current '>1</li>
                            </Link>
                            <Link to={`/author/${paginationLink}/2/`} rel='next'>
                                <li className='pagination__item pagination__item--next'>2</li>
                            </Link>
                            <Link to={`/author/${paginationLink}/3/`}>
                                <li className='pagination__item pagination__item--next'>3</li>
                            </Link>
                            <Link to={`/author/${paginationLink}/2/`} rel='next'>
                                <li className='pagination__item pagination__item--next'>»</li>
                            </Link>
                            <Link to={`/author/${paginationLink}/${authorPages}/`}>
                                <li className='pagination__item pagination__item--next'>»»</li>
                            </Link>
                        </ul>
                    </Fragment>
                )
            }
        } else if (pageNumber === authorPages) {
            // Case for last page
            pagination = (
                <Fragment>
                    <ul className='pagination'>
                        <Link to={`/author/${paginationLink}/`}>
                            <li className='pagination__item pagination__item--next'>««</li>
                        </Link>
                        {pageNumber && pageNumber > 2 ? (
                            <Fragment>
                                <Link
                                    to={`/author/${paginationLink}/${pageNumber - 1}/`}
                                    rel='prev'>
                                    <li className='pagination__item pagination__item--next'>«</li>
                                </Link>
                                <Link
                                    to={`/author/${paginationLink}/${pageNumber - 1}/`}
                                    rel='prev'>
                                    <li className='pagination__item pagination__item--next'>
                                        {pageNumber - 1}
                                    </li>
                                </Link>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Link to={`/author/${paginationLink}/`}>
                                    <li className='pagination__item pagination__item--next'>«</li>
                                </Link>
                                <Link to={`/author/${paginationLink}/`} rel='prev'>
                                    <li className='pagination__item pagination__item--next'>
                                        {pageNumber - 1}
                                    </li>
                                </Link>
                            </Fragment>
                        )}
                        <Link to={`/author/${paginationLink}/${pageNumber}/`}>
                            <li className='pagination__item pagination__item--current '>
                                {pageNumber}
                            </li>
                        </Link>
                    </ul>
                </Fragment>
            )
        } else {
            // Cases in the middle
            pagination = (
                <Fragment>
                    <ul className='pagination'>
                        <Link to={`/author/${paginationLink}/`}>
                            <li className='pagination__item pagination__item--next'>««</li>
                        </Link>
                        {/* Case for 2 */}
                        {pageNumber === 2 ? (
                            <Fragment>
                                <Link to={`/author/${paginationLink}/`}>
                                    <li className='pagination__item pagination__item--next'>«</li>
                                </Link>

                                <Link to={`/author/${paginationLink}/`} rel='prev'>
                                    <li className='pagination__item pagination__item--next'>
                                        {pageNumber - 1}
                                    </li>
                                </Link>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Link
                                    to={`/author/${paginationLink}/${pageNumber - 1}/`}
                                    rel='prev'>
                                    <li className='pagination__item pagination__item--next'>«</li>
                                </Link>

                                <Link
                                    to={`/author/${paginationLink}/${pageNumber - 1}/`}
                                    rel='prev'>
                                    <li className='pagination__item pagination__item--next'>
                                        {pageNumber - 1}
                                    </li>
                                </Link>
                            </Fragment>
                        )}

                        <Link to={`/author/${paginationLink}/${pageNumber}/`}>
                            <li className='pagination__item pagination__item--current '>
                                {pageNumber}
                            </li>
                        </Link>

                        <Link to={`/author/${paginationLink}/${pageNumber + 1}/`} rel='next'>
                            <li className='pagination__item pagination__item--next'>
                                {pageNumber + 1}
                            </li>
                        </Link>
                        <Link to={`/author/${paginationLink}/${pageNumber + 1}/`} rel='next'>
                            <li className='pagination__item pagination__item--next'>»</li>
                        </Link>
                        <Link to={`/author/${paginationLink}/${authorPages}/`}>
                            <li className='pagination__item pagination__item--next'>»»</li>
                        </Link>
                    </ul>
                </Fragment>
            )
        }

        const blog = get(this, 'props.data.allContentfulLensesBlog.edges')
        const tags = get(this, 'props.data.allContentfulTags.edges')
        const authors = get(this, 'props.data.allContentfulAuthor.edges')
        const categories = get(this, 'props.data.allContentfulCategories.edges')

        const dateOptions = {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        } as const

        // AUTHOR NAMES
        let authorNames = []
        blog.forEach((authorNode) => {
            authorNames.push(authorNode.node.author.name)
        })

        let seoNumber = Number.isNaN(pageNumber) ? ' ' : `Page ${pageNumber ? pageNumber : ' '}`

        return (
            <Layout>
                <Helmet>
                    <title>{`${author} ${seoNumber}`} | Lenses.io</title>
                    <meta name='twitter:text:title' content={author} />
                    <meta name='description' content={`${author} blog on DataOps ${seoNumber}`} />
                    <meta property='og:description' content={`${author} blog on DataOps`} />
                    <meta property='twitter:description' content={`${author} blog on DataOps`} />
                    <meta name='keywords' content={`${pageSEO.keywords.keywords} ${seoNumber}`} />
                    <meta name='twitter:card' content='summary_large_image' />
                    <meta name='twitter:site' content='@lensesio' />
                    <meta property='og:title' content={author} />
                    <meta property='twitter:title' content={author} />
                    <meta property='og:image' content={'https:' + pageSEO.oGimage.fluid.src} />
                    <meta property='twitter:image' content={'https:' + pageSEO.oGimage.fluid.src} />
                    <link
                        rel='stylesheet'
                        href='https://cdn.jsdelivr.net/npm/docsearch.js@2/dist/cdn/docsearch.min.css'
                    />
                    <script
                        type='text/javascript'
                        src='https://cdn.jsdelivr.net/npm/docsearch.js@2/dist/cdn/docsearch.min.js'
                        // onLoad does not expect strings,
                        // but string is required for docsearch to be functional
                        // @ts-expect-error
                        onLoad="docsearch({apiKey: '32e017aaf6842be76ecbac45550b35fa',
                                     appId: '84ASK1T3ST',
                                     indexName: 'dev_BLOG',
                                     inputSelector: '#searchbox input',
                                     debug: true})"
                        async></script>
                </Helmet>

                <section className='pb-5 pt-5 testing'>
                    <div className='container pt-5'>
                        <div className='row mt-5 mb-3'>
                            <div className='col-md-12 col-md-12 text-center pt-0'>
                                <h1 className='exclude-algolia-title text-capitalize'>
                                    {pageTitle}
                                </h1>
                            </div>

                            <div className='col-12'>
                                <div id='searchbox' role='search' className='w-100 d-flex'>
                                    <input
                                        type='text'
                                        className='pl-0 border-0 searchboxinput'
                                        name='q'
                                        autoComplete='off'
                                        placeholder='Search blog'
                                        autoFocus
                                    />{' '}
                                    <i
                                        style={{ marginTop: '15px', color: '#666666' }}
                                        className='fa fa-search ml-2'></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* AFTER SINGLE DISPLAY */}
                <div className='d-flex flex-wrap mobile-margin-reset'>
                    <div className='offset-lg-1 offset-0'></div>
                    <div className='col-lg-8 col-12 px-3 mobile-padding-reset'>
                        <div className='row mobile-margin-reset'>
                            {blog.map(({ node }) => {
                                let tempDate = node.date
                                let dateToDisplay = new Date(tempDate)
                                let year = dateToDisplay.getFullYear()
                                let month = dateToDisplay.getMonth()
                                month++
                                // Getting the month from the date & properly formatting - used in the url
                                let monthString = ('0' + month).slice(-2)
                                return (
                                    <div
                                        className='col-md-6 col-sm-12 offset-sm-0 offset-md-0 offset-1 mt-0 p-5 col-10 scale-on-hover mobile-padding-reset mobile-margin-top'
                                        key={node.id}>
                                        <div
                                            style={{
                                                boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
                                            }}>
                                            <a href={`/blog/${year}/${monthString}/${node.slug}/`}>
                                                <GatsbyImage
                                                    className='w-100'
                                                    image={node.heroimage.gatsbyImageData}
                                                    alt={author}
                                                />
                                            </a>
                                            <div className='height-250 p-3'>
                                                <a
                                                    href={`/blog/${year}/${monthString}/${node.slug}/`}>
                                                    <h2 className='paragraph-title f-18 pb-3 lh-1-3'>
                                                        {node.title}
                                                    </h2>
                                                    <div className='mt-1'>
                                                        <p className='paragraph-text'>
                                                            {' '}
                                                            {node.subtitle.substring(0, 70)}...
                                                        </p>
                                                    </div>
                                                </a>
                                                <div className='d-flex blog-image-author blog-image-author-static'>
                                                    {node.author.display ? (
                                                        <Fragment>
                                                            <a
                                                                href={`/author/${node.author.name
                                                                    .replace(/\s+/g, '-')
                                                                    .toLowerCase()}/`}>
                                                                <GatsbyImage
                                                                    className='author-photo-blog mr-3'
                                                                    image={
                                                                        node.author.image
                                                                            .gatsbyImageData
                                                                    }
                                                                    alt={node.author.name}
                                                                />
                                                            </a>
                                                            <p className='pl-2 f-14 mr-3 fw-600'>
                                                                <a
                                                                    href={`/author/${node.author.name
                                                                        .replace(/\s+/g, '-')
                                                                        .toLowerCase()}/`}>
                                                                    <span className='primary-text'>
                                                                        {node.author.name}
                                                                    </span>
                                                                </a>
                                                                <br />
                                                                {dateToDisplay.toLocaleDateString(
                                                                    'en-US',
                                                                    dateOptions,
                                                                )}
                                                            </p>
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment>
                                                            <GatsbyImage
                                                                className='author-photo-blog mr-3'
                                                                image={
                                                                    node.author.image
                                                                        .gatsbyImageData
                                                                }
                                                                alt={node.author.name}
                                                                loading='eager'
                                                            />
                                                            <p className='pl-2 f-14 mr-3 fw-600'>
                                                                {node.author.name}
                                                                <br />
                                                                {dateToDisplay.toLocaleDateString(
                                                                    'en-US',
                                                                    dateOptions,
                                                                )}
                                                            </p>
                                                        </Fragment>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {/* END OF FOR ABOVE ^ */}
                        </div>
                    </div>

                    <div className='col-md-2 col-10 d-none d-lg-block' id='sidebar'>
                        <div id='scroller-anchor'></div>
                        <div id='scroller' style={{ zIndex: 1 }}>
                            <p className='blog-list-title mb-1'>Categories</p>
                            <a className='blog-list-filter' href='/blog/'>
                                {' '}
                                All{' '}
                            </a>
                            <br />
                            {categories.map((category) => {
                                if (category.node.lenses_blog) {
                                    return (
                                        <Link
                                            key={category.node.id}
                                            className='blog-list-filter'
                                            to={`/categories/${category.node.categoryTitle.toLowerCase()}/`}>
                                            {category.node.categoryTitle}
                                            <br />
                                        </Link>
                                    )
                                }
                            })}
                            <br />
                            <p className='blog-list-title mb-1'>Authors</p>
                            {/* AUTHORS */}
                            <a className='blog-list-filter' href='/blog/'>
                                {' '}
                                All
                                <br />
                            </a>
                            {authors.map((author) => {
                                if (author.node.display) {
                                    if (author.node.lenses_blog) {
                                        return (
                                            <Link
                                                key={author.node.id}
                                                className='blog-list-filter'
                                                to={`/author/${author.node.name
                                                    .replace(/\s+/g, '-')
                                                    .toLowerCase()}/`}>
                                                {author.node.name}
                                                <br />
                                            </Link>
                                        )
                                    }
                                }
                            })}
                            <br />
                            {/* {Tags} */}
                            <p className='blog-list-title mb-1'>Tags</p>
                            <a className='blog-list-filter' href='/blog/'>
                                {' '}
                                All{' '}
                            </a>
                            <br />
                            {tags.map((tag) => {
                                if (tag.node.lenses_blog) {
                                    return (
                                        <Link
                                            key={tag.node.id}
                                            className='blog-list-filter'
                                            to={`/tags/${tag.node.tagTitle.toLowerCase()}/`}>
                                            {tag.node.tagTitle}
                                            <br />
                                        </Link>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-wrap my-5 '>
                    <div className='mx-auto'>{pagination}</div>
                </div>
            </Layout>
        )
    }
}

export default BlogAuthorListTemplate

export const pageQuery = graphql`
    query LensesBlogAuthorListTemplateIndexQuery($skip: Int!, $limit: Int!, $author: String!) {
        site {
            siteMetadata {
                title
            }
        }
        allContentfulCategories {
            edges {
                node {
                    id
                    categoryTitle
                    lenses_blog {
                        categories {
                            categoryTitle
                        }
                    }
                }
            }
        }
        allContentfulTags {
            edges {
                node {
                    tagTitle
                    id
                    lenses_blog {
                        tags {
                            tagTitle
                        }
                    }
                }
            }
        }
        allContentfulAuthor {
            edges {
                node {
                    id
                    name
                    display
                    lenses_blog {
                        author {
                            name
                            title
                        }
                    }
                }
            }
        }
        allContentfulLensesBlog(
            limit: $limit
            skip: $skip
            sort: { fields: date, order: DESC }
            filter: { author: { name: { eq: $author } } }
        ) {
            edges {
                node {
                    title
                    slug
                    date
                    id
                    subtitle
                    description
                    heroimage {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                    author {
                        name
                        display
                        image {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
        }
        allContentfulPages(filter: { pageReference: { pageIdentifier: { eq: "BlogAuthors" } } }) {
            edges {
                node {
                    title {
                        title
                    }
                    keywords {
                        keywords
                    }
                    description {
                        description
                    }
                    oGimage {
                        fluid(quality: 100) {
                            src
                        }
                    }
                }
            }
        }
    }
`
